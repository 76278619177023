<template>
    <v-alert text dense dismissible tile type="warning"
             v-if="!usingSupportedBrowser"
             class="mb-0" style="width: 100%;">
        You are using an unsupported browser.
        For a better experience please switch to

        <span v-for="(browser, i) of supportedBrowsers" :key="i">
            <strong>
                <a class="warning--text text-decoration-none"
                   :href="browser.downloadLink">{{browser.name}}</a>
            </strong>

            <span v-if="i < supportedBrowsers.length - 1">, </span>
            <span v-if="i == supportedBrowsers.length - 2">or </span>
        </span>.
    </v-alert>
</template>

<script>
    export default {
        name: "UnsupportedBrowserWarning",
        data: () => ({
            supportedBrowsers: [
                {
                    name: "Safari",
                    downloadLink: "https://support.apple.com/en-gb/HT204416",
                    checkCondition: /Version\/[\d.]+.*Safari/.test(navigator.userAgent) && (/(iPad|iPhone|iPod)/.test(navigator.userAgent) && !window.MSStream || !/(iPad|iPhone|iPod)/.test(navigator.userAgent)),
                },
                {
                    name: "Firefox",
                    downloadLink: "https://www.mozilla.org/en-US/firefox/new/",
                    checkCondition: typeof InstallTrigger !== 'undefined',
                },
                {
                    name: "Edge",
                    downloadLink: "https://www.microsoft.com/en-us/edge",
                    checkCondition: window.navigator.userAgent.indexOf("Edg") > -1,
                },
                {
                    name: "Chrome",
                    downloadLink: "https://www.google.com/chrome/",
                    checkCondition: typeof (window.chrome) !== "undefined",
                },
            ],
        }),
        computed: {
            usingSupportedBrowser() {
                return this.supportedBrowsers.map(b => b.checkCondition).find(c => c);
            },
        },
    };
</script>